<template>
  <div
    class="low-balance-modal"
    data-t="low-balance-modal"
    :class="{ mobile: isMobile }"
  >
    <StIcon
      v-if="isMobile"
      class="close-button"
      name="cross-large"
      size="20"
      @click="close"
    />
    <div class="content">
      <StIcon
        class="wallet-icon"
        name="wallet-solid"
        :size="isMobile ? 48 : 64"
      />
      <div data-t="low-balance-description" class="description">
        <h2 class="title">{{ t('payments.lowBalance') }}</h2>
        <div class="description-text-wrapper">
          <p class="description-text">
            {{ t('payments.lowBalanceDescription') }}
          </p>
          <p class="description-text">
            {{ t('payments.lowBalanceDescription2') }}
          </p>
        </div>
      </div>
    </div>
    <div class="footer">
      <StButton
        :size="isMobile ? 'l' : 'xl'"
        type="secondary"
        class="deposit-button"
        data-t="deposit-button"
        :to="{
          query: { ...route.query, modal: 'payments' },
        }"
      >
        {{ t('payments.makeDeposit') }}
      </StButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { isMobile } = usePlatform()

function close() {
  router.replace({
    query: { ...route.query, modal: undefined },
  })
}
</script>

<style scoped>
.low-balance-modal {
  display: flex;
  flex-direction: column;

  width: 596px;
  height: 100%;
  padding: var(--spacing-600) var(--spacing-300);

  &.mobile {
    width: 100%;
    padding: 0;
    padding-top: var(--spacing-250);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  align-items: center;

  padding: 0;
}

.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
}

.description {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-075);
  align-items: center;
}

.description-text {
  max-width: 300px;
  margin: 0;

  font: var(--desktop-text-md-medium);
  color: var(--text-secondary);
  text-align: center;
}

.wallet-icon {
  background-color: var(--icon-tertiary);
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-400);
}

.deposit-button {
  width: 100%;
}

.close-button {
  position: absolute;
  top: var(--spacing-400);
  right: var(--spacing-200);
}

.mobile {
  .content {
    padding: var(--spacing-200);
    padding-top: var(--spacing-400);
  }

  .description {
    gap: var(--spacing-050);
  }

  .description-text {
    font: var(--mobile-text-content-regular);
  }

  .title {
    font: var(--mobile-headline-semibold);
  }

  .deposit-button {
    width: 100%;
  }

  .footer {
    margin-top: 0;
    padding: var(--spacing-200);
  }
}
</style>
